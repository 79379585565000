@import '@/styles/variables.scss';






























































































































































.v-application .v-card--small-tile:not(.v-card .v-card) {
  max-width: 230px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
  background: #ecf1f9;
  border: rgb(70 89 193 / 100%) !important;
  transition: all ease-in-out 0.3s;
  flex: 1 1 auto;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &:hover {
      transform: scale(1.1);
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    max-width: none;
  }
}

.v-card__text h6 {
  max-width: 200px;
}
