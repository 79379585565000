@import '@/styles/variables.scss';

































































































































.vimeo .v-responsive__sizer {
  padding-bottom: 56%;
  z-index: 1 !important;
}
.vimeo {
  &:hover {
    cursor: pointer;
  }
}
.vimeo__controls {
  justify-content: center;
  .v-btn-toggle button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 0 !important;
  }
}
.v-card__vimeo {
  height: 100%;
  iframe {
    position: absolute;
    top: 0;
    height: 100%;
  }
}
