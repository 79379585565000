@import '@/styles/variables.scss';























































































































































@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-btn--contract {
    .v-btn__content {
      font-size: 12px !important;
    }
  }
}
